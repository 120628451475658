* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* ********* Global Color styling for the full app ********* */
:root {
  --main-bg-color: #f3f2ef;
  --Grey1: #333333;
  --Grey2: #4f4f4f;
  --Grey3: #828282;
  --Grey4: #bdbdbd;
  --Grey5: #e0e0e0;
  --Grey5--hover: #c5c5c5;
  --Blue: #4a9ef7;
  --Blue-hover: #1f86f4;
  --Container-grey: #f0f0f0;
  --Container-grey-hover: #d1d1cf;
  --Container-text: #707683;
  --Secondary-green: #14a6a6;
  --Secondary-green--hover: #0d7979;

  --color-grey-50: #f7fafc;
  --color-grey-100: #e3e8ee;
  --color-grey-dark-100: #7a8394;
  --color-grey-200: #c1c9d2;
  --color-grey-300: #a3acb9;
  --color-grey-400: #8792a2;
  --color-grey-500: #697386;
  --color-grey-600: #59617b;
  --color-grey-700: #3c4257;
  --color-grey-800: #2a2f45;
  --color-grey-900: #1a1f36;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
}

h3 {
  margin: 0;
}
ul li {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}
.error-input {
  border: none;
  border: 1px solid red !important;
  border-radius: 4px;
}

.error-div {
  color: #f44336;
  margin-top: -10px;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.date-helper-text {
  margin: 0px !important;
  padding-top: 3px !important;
  box-shadow: none !important;
  animation: slowanimate 0.5s ease both;
}

#pin-helper-text {
  animation: slowanimate 0.5s ease both;
}

/* animation for error .date-helper-text*/
@keyframes slowanimate {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(1px);
  }
}
